.about-us-container {
    text-align: center;
}

.image-scroller {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
    width: 100%;
    height: 400px;
}

.image-scroller::-webkit-scrollbar {
    display: none;
}

.scroller-content {
    display: flex;
    align-items: center;
}

.scroller-image {
    max-height: 400px;
    margin-right: 10px;
    flex-shrink: 0;
    border-radius: 2%;
}

.timeline-container {
    text-align: left;
    padding: 40px;
    max-width: 800px;
    margin: auto;
}

.timeline-heading {
    text-transform: uppercase;
    color: #2a2a2a;
    font-weight: normal;
}

.timeline-title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: left;
    color: #555;
}

.timeline {
    position: relative;
    padding-left: 30px;
    border-left: 2px solid #a00;
}

.timeline-item {
    position: relative;
    margin-bottom: 30px;
    padding-left: 20px;
}

.timeline-marker {
    position: absolute;
    left: -8px;
    top: 5px;
    width: 16px;
    height: 16px;
    background: #a00;
    border-radius: 50%;
    border: 2px solid white;
}

.timeline-year {
    font-size: 1.6rem;
    color: #a00;
    font-weight: bold;
}

.timeline-content p {
    font-size: 14px;
    color: #666;
    line-height: 1.6;
}

.history-container {
    text-align: left;
    padding: 40px;
    max-width: 800px;
    margin: auto;
}

.history-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.sub-heading {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    color: #555;
}

.chapters-list {
    list-style-type: circle;
    padding-left: 20px;
    color: #666;
}
@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@400;700&display=swap');

.meet-the-brothers-component {
    font-family: 'Mohave', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    background-color: #ffffff;
    color: #252525;
    overflow-x: hidden; /* Add this line to prevent horizontal scrolling */
}

.meet-the-brothers-hero {
    position: relative;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
}

.meet-the-brothers-component h1 {
    font-size: 4em;
    margin-bottom: 10vh;
}

.meet-the-brothers-coat-arms {
    width: 5%;
    margin-top: -3%;
    margin-bottom: 2%;
}

.meet-the-brothers-leadership-section {
    width: 100%;
    padding: 20px;
    text-align: center;
}

.meet-the-brothers-leadership-section h2 {
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #881616;
}

.meet-the-brothers-leadership-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.meet-the-brothers-leadership-profile {
    text-align: center;
    padding: 15px;
}

.meet-the-brothers-leadership-profile-pic {
    width: 150px; /* Adjust size as necessary */
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.meet-the-brothers-leadership-name {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: -7px;
}

.meet-the-brothers-leadership-role {
    font-size: 1.2em;
    color: #666;
    margin-top: 5px;
    text-transform: none;
    padding-bottom: 10px;
}

.meet-the-brothers-class-group {
    margin-bottom: 40px;
    width: 100%;
}

.meet-the-brothers-class-group h2 {
    text-align: center;
    margin-bottom: 5vh;
    color: #881616;
    font-size: 2em;
}

.meet-the-brothers-user-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.meet-the-brothers-brother-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.09);
    text-align: center;
    width: 15%; /* Default width for larger screens */
    background-color: rgb(248, 248, 248);
    padding: 20px;
    color: black;
    cursor: pointer;
    transition: transform 0.2s;
    line-height: 1.2em; /* Adjust line height for better readability */
}

.meet-the-brothers-brother-card:hover {
    transform: scale(1.07);
}

.meet-the-brothers-user-name {
    padding-top: 30px;
    font-size: 1.2em;
    font-weight: bold;
}

.meet-the-brothers-user-role {
    font-size: 0.9em;
    color: #666;
    margin-top: 5px;
    text-transform: none;
}

.meet-the-brothers-user-major {
    font-size: 0.9em;
    color: #666;
    margin-top: 5px;
    text-transform: none;
}

.meet-the-brothers-user-graduation-year {
    font-size: 0.9em;
    color: #666;
    margin-top: 5px;
    text-transform: none;
}

.meet-the-brothers-profile-pic {
    padding: 10px;
    width: 100%; /* Ensure images scale with card */
    max-width: 150px; /* Limit image width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: -35px;
}

/* Add new styles for the executive board section */
.meet-the-brothers-executive-board-section {
    width: 100%;
    padding: 20px;
    text-align: center;
}

.meet-the-brothers-executive-board-section h2 {
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #881616;
}

.meet-the-brothers-executive-board-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.meet-the-brothers-executive-board-profile {
    text-align: center;
    padding: 15px;
}

.meet-the-brothers-executive-board-profile-pic {
    width: 150px; /* Adjust size as necessary */
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.meet-the-brothers-executive-board-name {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: -7px;
}

.meet-the-brothers-executive-board-role {
    font-size: 1.2em;
    color: #666;
    margin-top: 5px;
    text-transform: none;
    padding-bottom: 10px;
}

/* Add responsive styles */
@media (max-width: 768px) {
    .meet-the-brothers-user-grid {
        gap: 10px; /* Reduce gap between cards */
    }

    .meet-the-brothers-brother-card {
        width: 45%; /* Set width to 45% to fit two cards side by side with gap */
        padding: 10px; /* Adjust padding */
        font-size: 0.9em; /* Adjust text size */
    }

    .meet-the-brothers-leadership-profile-pic,
    .meet-the-brothers-profile-pic {
        width: 100px; /* Smaller image size for mobile */
        height: 100px;
    }

    .meet-the-brothers-leadership-name,
    .meet-the-brothers-user-name {
        font-size: 1em; /* Adjust name size */
    }

    .meet-the-brothers-leadership-role,
    .meet-the-brothers-user-role,
    .meet-the-brothers-user-major,
    .meet-the-brothers-user-graduation-year {
        font-size: 0.8em; /* Adjust role, major, and graduation year text sizes */
    }

    .meet-the-brothers-component h1,
    .meet-the-brothers-leadership-section h2,
    .meet-the-brothers-class-group h2,
    .meet-the-brothers-executive-board-section h2 {
        font-size: 1.5em; /* Adjust section header sizes */
    }

    .meet-the-brothers-hero {
        height: 60vh; /* Reduce hero section height */
    }
}

/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup Content */
.popup-content {
    background: #fff;
    border-radius: 10px;
    padding: 40px;
    width: 600px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Spacing between elements inside the popup */
}


/* User Profile Image */
.popup-user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

/* User Info */
.popup-user-info h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.popup-user-info p {
    font-size: 1em;
    color: #666;
    margin: 5px 0;
}

/* Close Button */
.popup-close-button {
    background: #881616;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    right: 20px;
    font-family: Mohave, serif;
    text-transform: uppercase;
}

.popup-close-button:hover {
    background: #670808;
    transition: background-color 0.3s ease-in-out;
}

/* Popup Main Container */
.popup-main {
    display: flex;
    flex-direction: row; /* Keep the horizontal layout */
    align-items: center; /* Vertically center the profile picture and details */
    justify-content: flex-start; /* Align content to the start (left) */
    gap: 30px; /* Add spacing between the profile picture and details */
    height: 100%; /* Ensure this container fills the vertical space */
}

/* Popup User Image Container */
.popup-user-image-container {
    flex-shrink: 0; /* Prevent the image size from shrinking */
    display: flex; /* Use flexbox for internal alignment */
    align-items: center; /* Center the image vertically within its parent */
    justify-content: center; /* Center the image horizontally within its parent */
}

/* Popup User Image */
.popup-user-image {
    width: 120px; /* Set a fixed size for the image */
    height: 120px;
    border-radius: 50%; /* Circular image */
    object-fit: cover; /* Maintain aspect ratio */
    padding: 0; /* Remove any additional spacing */
}

/* Popup Content */
.popup-content {
    background: #fff;
    border-radius: 15px;
    padding: 40px; /* Increased padding for better overall spacing */
    min-height: 33%;
    width: 500px; /* Slightly wider to accommodate content */
    max-width: 90%; /* Responsive sizing */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Spacing between elements inside the popup */
}

/* Popup User Details */
.popup-user-info {
    flex: 1; /* Ensure this section takes up extra space */
    text-align: left; /* Align details to the left side */
    padding: 10px; /* Add padding for spacing */
}

.popup-user-info h2 {
    font-size: 1.7em;
    margin: 0 0 10px;
    color: #252525;
}

.popup-user-info p {
    margin: 8px 0; /* More spacing between paragraphs */
    font-size: 1.1em; /* Slightly larger text */
    color: #666;
}

/* Popup Close Button */
.popup-close-button {
    background: #881616;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    bottom: 20px; /* Position close button at the bottom-right */
    right: 20px;
    font-size: 1em;
    font-weight: bold;
}

.popup-close-button:hover {
    background: #670808; /* Darker shade of red on hover */
}

/* Responsive Adjustments for Mobile Screens */
@media (max-width: 768px) {
    .popup-main {
        flex-direction: column; /* Stack profile picture and details */
        align-items: center; /* Center-align for mobile */
        gap: 20px;
    }

    .popup-user-info {
        text-align: center; /* Center-align on smaller screens */
        padding: 0; /* Remove extra padding for compact view */
    }

    .popup-content {
        padding: 20px; /* Slightly smaller padding for mobile */
        width: 90%;
    }

    .popup-close-button {
        bottom: 16px;
        right: 16px;
    }
}
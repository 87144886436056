.admin-page {
    font-family: 'Mohave', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    background-color: #ffffff;
    color: #252525;
    padding: 20px;
}

.admin-page h1, .admin-page h2 {
    color: #881616;
}

.admin-add-user {
    width: 100%;
    max-width: 600px;
    margin: 20px;
    padding: 20px;
    /*background-color: #f8f8f8;*/
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.09);*/
    /*border-radius: 10px;*/
}

.admin-add-user h2 {
    margin-bottom: 20px;
}

.admin-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.admin-input-group label {
    margin-bottom: 5px;
    font-size: 1em;
}

.admin-input-group input {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.admin-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.admin-buttons button {
    padding: 10px 20px;
    font-size: 1em;
    font-family: 'Mohave', sans-serif;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-buttons button.add {
    background-color: #881616;
    color: #ffffff;
}

.admin-buttons button.add:hover {
    background-color: #6b1212;
}

.admin-buttons button.update {
    background-color: #252525;
    color: #ffffff;
}

.admin-buttons button.update:hover {
    background-color: #1f1f1f;
}

.admin-buttons button.close {
    background-color: #787878;
    color: #ffffff;
}

.admin-buttons button.close:hover {
    background-color: #5e5e5e;
}

.admin-user-cards {
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    /*align-items: center;*/
    padding: 15px;
    /*text-align: ceter;*/
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.user-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.09);
    width: 250px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.user-card-img-small {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.user-card-info {
    display: flex;
    align-items: center;
    padding: 15px;
}

.user-card-text {
    text-align: left;
}

.user-card-info .user-card-name {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1.1em;
}

.user-card-info .user-card-role {
    font-size: .75em;
    color: #555;
    margin-top: 5px;
}

.admin-edit-user {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 700px;
    padding: 20px;
    background-color: #f8f8f8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1001;
}

.admin-edit-user h2 {
    margin-bottom: 20px;
}

.admin-edit-user .admin-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.admin-edit-user .admin-input-group label {
    flex-basis: 30%;
    margin-bottom: 0;
    font-size: 1em;
}

.admin-edit-user .admin-input-group input {
    flex-basis: 65%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.admin-edit-user .admin-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.admin-edit-user-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

/* Brodate Groups Section */
.brodate-groups {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.groups-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.brodate-group {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.09);
    padding: 15px;
    margin: 10px;
    width: 45%;
}

.admin-dashboard ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 20px;
}

.admin-dashboard li {
    text-transform: uppercase;
}

.admin-dashboard li a {
    text-decoration: none;
    color: #1c0f0f;
    font-size: 1.2em;
}

.admin-dashboard li a:hover {
    /*color: #6b1212;*/
}

.admin-input-group select {
    flex-basis: 65%; /* Match the layout of other inputs */
    padding: 10px; /* Same padding as text inputs */
    font-size: 1em; /* Match font size */
    border: 1px solid #ccc; /* Same border as the inputs */
    border-radius: 5px; /* Rounded corners same as inputs */
    background-color: #ffffff; /* Background color similar to inputs */
    color: #333; /* Text color */
}
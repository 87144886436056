/* Dashboard.css */

.new-dashboard-container {
    display: flex;
    flex-direction: column;
    padding: 10px 3%;
    z-index: 1; /* Lower z-index to avoid overlapping */
}

.dashboard-header {
    margin-left: 3%;
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    z-index: 1; /* Lower z-index to avoid overlapping */
}

.user-info {
    display: flex;
    align-items: center;
    z-index: 1; /* Lower z-index to avoid overlapping */
}

.profile-picture {
    width: 200px;
    height: auto;
    border-radius: 50%;
    margin-right: 20px;
}

.welcome-container {
    display: flex;
    flex-direction: column;
}

.welcome-message {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin: 0 0 0 20px;
}

.user-details {
    color: grey;
    margin-left: 20px;
}

.user-details p {
    margin: 3px 0;
}

.edit-icon {
    cursor: pointer;
    font-size: 24px;
    color: #555;
    margin-right: 5%;
}

.widgets {
    display: flex;
    padding: 10px;
}

.card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1;
    margin: -5px 10px 10px;
}

h2 {
    margin-top: 0;
}

.progress-card .progress-bar {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin: 13px 0;
}

.progress-card .progress {
    height: 20px;
    background-color: #9a0706;
}

.buttons-container {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.spoon-card {
    background: #860303;
    color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1;
    margin: -5px 10px 10px;
}

.calendar-card {
    display: flex;
    flex-direction: column;
}

.calendar-card .event {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.calendar-card .event-name {
    font-weight: bold;
    color: #333;
}

.calendar-card .event-date {
    color: #666;
    text-align: right;
}

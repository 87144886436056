/* Footer.css */
.footer {
    background-color: #333;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.footer p {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}
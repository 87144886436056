/* Header.css */

.header {
    background-color: #333;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensure items are centered vertically */
    position: relative;
    z-index: 10; /* Set high z-index for the header */
}

.logo {
    cursor: pointer;
    max-width: 40px;
    max-height: 40px;
    padding-left: 10px;
}

.hamburger-icon {
    display: none;
    background: none;
    color: #fff;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.nav-links {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: max-height 0.3s ease-in-out;
    z-index: 10; /* Set high z-index for the nav links */
}

.header-profile {
    display: flex;
    align-items: center;
} /* Container for profile name and picture */

.header-profile-picture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: -20px;
    margin-left: 10px;
} /* Profile picture styling */

.nav-links ul {
    list-style: none;
    text-transform: uppercase;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.login-link ul {
    list-style: none;
    text-transform: uppercase;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0 20px 0 0;
}

.nav-links ul li,
.login-link ul li {
    display: inline;
}

.nav-links ul li a,
.login-link ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.nav-links ul li a:hover,
.login-link ul li a:hover,
.nav-links ul li.active a,
.login-link ul li.active a {
    color: #FFD700FF;
}

.login-link {
    margin-left: auto;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    display: block;
    position: absolute;
    right: 0;
    background-color: #2a2a2a;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 20; /* Set higher z-index for the dropdown menu */
    padding: 12px;
    border-radius: 4px;
}

.dropdown-item {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    background: none;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
    color: #000;
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
        max-height: 0;
        flex-direction: column;
        align-items: center;
        background-color: #333;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 1;
        padding: 10px;
    }

    .nav-links.visible {
        display: flex;
        max-height: 300px;
    }

    .nav-links ul {
        display: block;
        text-align: center;
    }

    .nav-links ul li {
        display: block;
        padding: 10px 0;
        width: 100%;
    }

    .hamburger-icon {
        display: block;
    }
}